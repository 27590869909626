<template>
  <v-sheet class="" id="customer">
    <v-row>
      <v-col
        style="position:sticky;top:5rem;z-index:90;!important;padding:2px!important"
        class="d-flex white grey lighten-3"
      >
        <v-col md="6" class="my-auto">
          <h1 class="custom-header-blue-text m-0">{{ title }}</h1>
        </v-col>
        <v-col md="6" class="text-right">
          <v-btn
            :disabled="pageLoading || !formValid"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            @click="updateOrCreate"
          >
            Save
          </v-btn>
          <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
            Cancel
          </v-btn>
        </v-col>
      </v-col>
      <div class="d-flex">
        <v-col md="8">
          <v-form
            ref="templateForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate"
          >
            <v-container fluid>
              <v-row>
                <v-col md="3" class="my-auto py-0">
                  <label for="category" class="btx-label mt-"> Category </label>
                </v-col>
                <v-col md="9" class="py-0">
                  <SelectInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :items.sync="categoryList"
                    id="category"
                    item-text="name"
                    :rules="[vrules.required(template.category, 'Category')]"
                    :class="{ required: !template.category }"
                    item-value="slug"
                    v-model="template.category"
                    placeholder="Select Category"
                  ></SelectInput>
                </v-col>
                <v-col md="3" class="my-auto py-0">
                  <label for="category" class="btx-label mt-">Template Category </label>
                </v-col>
                <v-col md="9" class="py-0">
                  <SelectInput
                    hide-details
                    :disabled="$route.name == 'update-template' || pageLoading"
                    :loading="pageLoading"
                    :items.sync="templateCategory"
                    id="category"
                    item-text="text"
                    item-value="value"
                    :rules="[vrules.required(template.template_category, 'Template Category')]"
                    :class="{ required: !template.template_category }"
                    v-model="template.template_category"
                    placeholder="Select Template Category"
                  ></SelectInput>
                </v-col>
                <v-col md="3" class="my-auto py-0">
                  <label for="template_type" class="btx-label mt-2">Template Type</label>
                </v-col>
                <v-col md="9" class="py-0 mt-0 custom">
                  <v-radio-group
                    id="template_type"
                    hide-details
                    dense
                    mandatory
                    row
                    style="width: 100%"
                    class="d-flex"
                    v-model="template.template_type"
                  >
                    <v-row>
                      <v-col md="4" class="pr-0">
                        <v-radio
                          value="sms"
                          class="font-level-2-bold px-2 py-2 mr-0 d-flex justify-space-between"
                          label="SMS"
                          :class="
                            template.template_type == 'sms' ? 'orange lighten-4' : 'grey lighten-5'
                          "
                          :color="template.template_type == 'sms' ? 'orange ' : ''"
                          text-color="white"
                          icon="mdi-message-outline"
                          x-large
                          style="background-color: #f2d8b8; width: auto"
                          :style="[
                            template.template_type == 'sms'
                              ? { border: ' 1px solid #e08310 !important' }
                              : { border: '1px solid #ECEFF1 !important' },
                          ]"
                        >
                          <template v-slot:label>
                            <div
                              class="d-flex justify-space-between align-center"
                              style="width: 100%"
                            >
                              <span class="font-level-3-bold"> SMS </span>
                              <v-icon
                                x-large
                                :color="
                                  template.template_type == 'sms'
                                    ? 'orange darken-2'
                                    : 'grey lighten-1'
                                "
                                >mdi-message</v-icon
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-col>
                      <v-col md="4" class="custom-radio pr-0">
                        <v-radio
                          value="whatsapp"
                          class="font-level-2-bold px-2 py-2 mr-0 d-flex justify-space-between"
                          label="whatsapp"
                          :class="
                            template.template_type == 'whatsapp'
                              ? 'orange lighten-4'
                              : 'grey lighten-5'
                          "
                          :color="template.template_type == 'whatsapp' ? 'orange ' : ''"
                          text-color="white"
                          icon="mdi-message-outline"
                          x-large
                          style="background-color: #f2d8b8; margin-right: 0px !important"
                          :style="[
                            template.template_type == 'whatsapp'
                              ? { border: ' 1px solid #e08310 !important' }
                              : { border: '1px solid #ECEFF1 !important' },
                          ]"
                        >
                          <template v-slot:label>
                            <div
                              class="d-flex justify-space-between align-center"
                              style="width: 100%"
                            >
                              <span class="font-level-3-bold"> Whatsapp </span>
                              <v-icon
                                x-large
                                :color="
                                  template.template_type == 'whatsapp'
                                    ? 'orange darken-2'
                                    : 'grey lighten-1'
                                "
                                >mdi-whatsapp</v-icon
                              >
                            </div>
                          </template>
                        </v-radio></v-col
                      >
                      <v-col md="4">
                        <v-radio
                          value="email"
                          class="font-level-2-bold px-2 py-2 mr-0 d-flex justify-space-between"
                          label="email"
                          :class="
                            template.template_type == 'email'
                              ? 'orange lighten-4'
                              : 'grey lighten-5'
                          "
                          :color="template.template_type == 'email' ? 'orange ' : ''"
                          text-color="white"
                          icon="mdi-message-outline"
                          x-large
                          style="background-color: #f2d8b8"
                          :style="[
                            template.template_type == 'email'
                              ? { border: ' 1px solid #e08310 !important' }
                              : { border: '1px solid #ECEFF1 !important' },
                          ]"
                        >
                          <template v-slot:label>
                            <div
                              class="d-flex justify-space-between align-center"
                              style="width: 100%"
                            >
                              <span class="font-level-3-bold"> email </span>
                              <v-icon
                                x-large
                                :color="
                                  template.template_type == 'email'
                                    ? 'orange darken-2'
                                    : 'grey lighten-1'
                                "
                                >mdi-email</v-icon
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-col>
                    </v-row>
                    <!-- <v-radio
                      x-large
                      :color="template.template_type == 'email' ? 'red darken-1' : ''"
                      :outlined="template.template_type == 'email' ? true : false"
                      class="font-level-4-bold"
                      value="email"
                    >
                      <v-radio color="white" value="whatsapp"></v-radio>
                      <span
                        class="font-level-4-bold"
                        :class="
                          template.template_type == 'email'
                            ? 'red--text text--darken-1'
                            : 'black--text'
                        "
                      >
                        Email</span
                      >
                      <v-icon x-large color="red darken-1" class="ml-2">mdi-email-outline</v-icon>
                    </v-radio> -->
                    <!-- </v-btn-toggle> -->
                  </v-radio-group>
                </v-col>
                <v-col md="3" class="my-auto py-0">
                  <label for="template_name" class="btx-label mt-2">Template Name</label> </v-col
                ><v-col md="9" class="my-auto py-0">
                  <TextInput
                    :hide-details="/^[a-z_][a-z0-9_]*$/.test(template.name)"
                    :disabled="$route.name == 'update-template' || pageLoading"
                    :loading="pageLoading"
                    id="template_name"
                    :rules="[
                      vrules.required(template.name, 'Template name'),
                      () =>
                        /^[a-z_][a-z0-9_]*$/.test(template.name) ||
                        'only lower capital letter, underscore and numeric value in between are allowed',
                    ]"
                    :class="{ required: !template.name }"
                    placeholder="Template name"
                    v-model="template.name"
                  ></TextInput>
                </v-col>

                <v-col md="3" class="my-auto py-0">
                  <label for="language" class="btx-label mt-">Language </label>
                </v-col>
                <v-col md="9" class="py-0">
                  <SelectInput
                    hide-details
                    :disabled="pageLoading || $route.name == 'update-template'"
                    :loading="pageLoading"
                    :rules="[vrules.required(template.language, 'language')]"
                    :class="{ required: !template.language }"
                    :items.sync="languageList"
                    id="template"
                    item-text="text"
                    item-value="value"
                    v-model="template.language"
                    placeholder="Select language"
                  ></SelectInput>
                </v-col>

                <v-expansion-panels
                  active-class="elevation-0"
                  class="mt-4 v-expansion-panel-custom elevation-0"
                  accordion
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="orange lighten-5 pr-5 elevation-0"
                      style="border-left: 20px solid #ffcc80 !important"
                    >
                      <span class="btx-label mt-2 ml-3"> Header</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="elevation-0"
                      style="background: rgb(255 249 240)"
                    >
                      <v-row>
                        <v-col md="3" class="my-auto py-0"> </v-col>
                        <v-col md="9" class="py-0">
                          <div class="d-flex">
                            <v-chip-group
                              mandatory
                              @change="headerTypeChange"
                              v-model="template.header_type"
                            >
                              <v-chip
                                :class="{
                                  'blue darken-3 white--text': template.header_type == button.value,
                                }"
                                label
                                v-for="(button, index) of headerTypeList"
                                size="25"
                                :key="index"
                                :value="button.value"
                                >{{ button.text }}</v-chip
                              >
                            </v-chip-group>

                            <div
                              v-if="template.header_type && template.header_type == 'text'"
                              md="12"
                              class="d-flex justify-end template align-center"
                              style="padding: 0px !important; width: 100% !important"
                            >
                              <v-btn
                                :disabled="
                                  pageLoading || onlyOneParameter(template.header_text)
                                    ? true
                                    : false
                                "
                                :loading="pageLoading"
                                class="white--text ml-6"
                                x-small
                                color="blue darken-4"
                                tile
                                outlined
                                v-on:click="createHeaderParameters"
                              >
                                <span style="font-size: 10px; font-weight: 700"
                                  >Add Parameters</span
                                >
                              </v-btn>
                            </div>
                          </div>

                          <!-- <SelectInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :items.sync="headerTypeList"
                    @change="template.file = []"
                    id="header_type"
                    item-text="text"
                    item-value="value"
                    v-model="template.header_type"
                    placeholder="Select header type"
                  ></SelectInput> -->
                          <template v-if="template.header_type != 'none'">
                            <template v-if="template.header_type && template.header_type == 'text'">
                              <!-- <div
                                md="12"
                                class="d-flex justify-end template"
                                style="padding: 0px !important; width: 100% !important"
                              >
                                <v-btn
                                  :disabled="
                                    pageLoading || onlyOneParameter(template.header_text)
                                      ? true
                                      : false
                                  "
                                  :loading="pageLoading"
                                  class="white--text ml-6"
                                  x-small
                                  color="blue darken-4"
                                  tile
                                  outlined
                                  v-on:click="createHeaderParameters"
                                >
                                  <span style="font-size: 10px; font-weight: 700"
                                    >Add Parameters</span
                                  >
                                </v-btn>
                              </div> -->
                              <template>
                                <!-- <v-col md="3" class="py-0">
                                <label for="header_text" class="btx-label mt-2">Header Text </label>
                              </v-col> -->
                                <v-row>
                                  <v-col md="12" class="py-0">
                                    <!-- <TextAreaInput
                                    v-model="template.header_text"
                                    counter="60"
                                    :disabled="pageLoading"
                                    auto-grow
                                    :rules="[
                                      (v) =>
                                        (v && !hideDetails ? v.length <= 60 : true) ||
                                        `${placeholder} maximum 250 characters`,
                                    ]"
                                    :loading="pageLoading"
                                    :class="{
                                      required:
                                        !template.header_text || template.header_text.length > 60,
                                    }"
                                    id="header_text"
                                    @keypress="checkDoubleCurly($event, template.header_text)"
                                    placeholder="Header Text"
                                  ></TextAreaInput> -->
                                    <TextInput
                                      v-model="template.header_text"
                                      :disabled="pageLoading"
                                      auto-grow
                                      counter="60"
                                      :rules="[
                                        (v) =>
                                          (v && !hideDetails ? v.length <= 60 : true) ||
                                          `${placeholder} maximum 60 characters`,
                                      ]"
                                      :class="{
                                        required:
                                          !template.header_text || template.header_text.length > 60,
                                      }"
                                      :loading="pageLoading"
                                      id="header_text"
                                      @keypress="checkDoubleCurly($event, template.header_text)"
                                      placeholder="Header Text"
                                    ></TextInput>
                                  </v-col>
                                  <v-col md="12" class="d-flex py-0">
                                    <template v-for="(item, index) of header_text_parameter">
                                      <v-col md="3" class="m py-0" :key="index + 12">
                                        <label for="" class="mt-4">
                                          <template>&#123;&#123;</template>{{ index + 1
                                          }}<template>&#125;&#125;</template>
                                        </label></v-col
                                      ><v-col
                                        md="9"
                                        class="py-0 d-flex item align-start"
                                        :key="index"
                                      >
                                        <TextInput
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          v-model="item.value"
                                          id="template_name"
                                          placeholder="Template name"
                                        ></TextInput>
                                        <v-icon
                                          class="ml-4 mt-4"
                                          @click="removeHeaderParameter(index)"
                                          color="red"
                                          >mdi-close</v-icon
                                        >
                                      </v-col>
                                    </template>
                                  </v-col>
                                </v-row>
                              </template>
                            </template>

                            <template
                              v-else-if="template.header_type && template.header_type != 'text'"
                            >
                              <!-- <v-col md="3" class="py-0">
                              <label for="document" class="btx-label mt-4">
                                {{ template.header_type === "image" ? "Image" : "Document" }}
                              </label>
                            </v-col> -->
                              <v-col style="padding-left: 0px !important" md="12" class="py-0">
                                <FileUpload
                                  :accept="
                                    template.header_type === 'image' ? 'image' : 'application'
                                  "
                                  id="document"
                                  :allowAddMore="false"
                                  @input="inputFileImage"
                                  @clearFile="fileRemove"
                                  v-model="template.file"
                                ></FileUpload>
                              </v-col>
                            </template>
                          </template>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels
                  v-model="bodyAccordion"
                  class="mt-4 v-expansion-panel-custom elevation-0"
                  accordion
                >
                  <v-expansion-panel class="elevation-0" expand v-model="bodyAccordion">
                    <v-expansion-panel-header
                      class="orange lighten-5 pr-5 elevation-0"
                      style="border-left: 20px solid #ffcc80 !important"
                    >
                      <!-- <v-icon class="mr-4" color="grey"> mdi-page-layout-body </v-icon> -->
                      <span class="btx-label mt-2 ml-3"> Body</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="background: rgb(255 249 240)">
                      <div
                        md="12"
                        class="d-flex justify-end template"
                        style="width: 100%; padding: 0px !important"
                      >
                        <v-btn
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="white--text mt-2 ml-4"
                          small
                          color="blue darken-4"
                          outlined
                          tile
                          v-on:click="createBodyParameters"
                        >
                          <span style="font-size: 10px; font-weight: 700" class="text"
                            >Add Body Parameters</span
                          >
                        </v-btn>
                      </div>
                      <v-row>
                        <v-col md="3" class="py-0">
                          <!-- <label for="body_text" class="btx-label mt-2">Body Text </label> -->
                        </v-col>
                        <v-row>
                          <v-col md="12" class="py-0">
                            <TextAreaInput
                              v-model="template.body_text"
                              counter="1024"
                              auto-grow
                              @keypress="checkDoubleCurly($event, template.body_text)"
                              :disabled="pageLoading"
                              :rules="[
                                vrules.required(template.body_text, 'Body Text'),
                                (v) =>
                                  (v && !hideDetails ? v.length <= 1024 : true) ||
                                  `Body Text maximum 250 characters`,
                              ]"
                              :loading="pageLoading"
                              :class="{
                                required: !template.body_text || template.body_text.length > 1024,
                              }"
                              id="body_text"
                              placeholder="Body Text"
                            ></TextAreaInput>
                          </v-col>
                          <!-- <v-col md="3"> </v-col> -->
                          <v-col md="12" class="py-0 px-2" style="padding-top: 0px !important">
                            <template v-for="(item, index) of body_text_parameter">
                              <div class="d-flex" :key="index">
                                <div style="width: 25%" class="py-0">
                                  <label for="" class="mt-4">
                                    <template>&#123;&#123;</template>{{ index + 1
                                    }}<template>&#125;&#125;</template>
                                  </label>
                                </div>
                                <div style="width: 75%" class="py-0 d-flex">
                                  <TextInput
                                    hide-details
                                    dense
                                    :disabled="pageLoading"
                                    v-model="item.value"
                                    :loading="pageLoading"
                                    id="template_name"
                                    :placeholder="`Enter Parameter ${index + 1}`"
                                  ></TextInput>
                                  <v-icon
                                    :disabled="
                                      index + 1 != body_text_parameter.length ? true : false
                                    "
                                    class="ml-4"
                                    @click="removeBodyParameter(index)"
                                    color="red"
                                    >mdi-close</v-icon
                                  >
                                </div>
                              </div>
                            </template>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels class="mt-4 v-expansion-panel-custom" accordion>
                  <v-expansion-panel class="elevation-0">
                    <v-expansion-panel-header
                      class="orange lighten-5 pr-5 elevation-0"
                      style="border-left: 20px solid #ffcc80 !important"
                    >
                      <!-- <label for="footer_text" class="btx-label mt-2">Footer Text </label> -->
                      <span class="btx-label mt-2 ml-3"> Footer</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="background: rgb(255 249 240)">
                      <v-row>
                        <v-col md="3" class="py-0">
                          <!-- <label for="footer_text" class="btx-label mt-2">Footer Text </label> -->
                        </v-col>
                        <v-col md="9" class="py-0">
                          <!-- <TextAreaInput
                            v-model="template.footer_text"
                            counter="60"
                            :disabled="pageLoading"
                            :rules="[
                              (v) =>
                                (v && !hideDetails ? v.length <= 60 : true) ||
                                `${placeholder} maximum 250 characters`,
                            ]"
                            auto-grow
                            :loading="pageLoading"
                            id="footer_text"
                            placeholder="Footer Text"
                          ></TextAreaInput> -->
                          <TextInput
                            v-model="template.footer_text"
                            counter="60"
                            :disabled="pageLoading"
                            :rules="[
                              (v) =>
                                (v && !hideDetails ? v.length <= 60 : true) ||
                                `${placeholder} maximum 250 characters`,
                            ]"
                            auto-grow
                            :loading="pageLoading"
                            id="footer_text"
                            placeholder="Footer Text"
                          ></TextInput>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels class="mt-4 v-expansion-panel-custom" accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="orange lighten-5 pr-5 elevation-0"
                      style="border-left: 20px solid #ffcc80 !important"
                    >
                      <!-- <label for="footer_text" class="btx-label mt-2">Button </label> -->
                      <span class="btx-label mt-2 ml-3"> Button</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="background: rgb(255 249 240)">
                      <v-row>
                        <v-col md="3"></v-col>
                        <v-col
                          md="9"
                          class="d-flex justify-space-between template"
                          style="padding: 0px !important"
                        >
                          <v-chip-group
                            mandatory
                            @change="buttonCategoryChange"
                            v-model="template.button_category_type"
                          >
                            <v-chip
                              :class="{
                                'blue darken-3 white--text':
                                  template.button_category_type == 'none',
                              }"
                              label
                              size="25"
                              value="none"
                              >None</v-chip
                            >
                            <v-chip
                              :class="{
                                'blue darken-3 white--text':
                                  template.button_category_type == 'quick_reply',
                              }"
                              label
                              size="25"
                              value="quick_reply"
                              >Quick Reply</v-chip
                            >
                            <v-chip
                              :class="{
                                'blue darken-3 white--text':
                                  template.button_category_type == 'action_type',
                              }"
                              label
                              size="25"
                              value="action_type"
                              >Action Type</v-chip
                            >
                          </v-chip-group>
                          <!-- <v-radio-group
                            @change="buttonCategoryChange"
                            dense
                            small
                            v-model="template.button_category_type"
                            row
                          >
                            <v-radio label="Quick Type" value="quick_reply"></v-radio>
                            <v-radio label="Action Type" value="action_type"></v-radio>
                          </v-radio-group> -->
                          <v-btn
                            v-if="template.button_category_type != 'none'"
                            :disabled="isButtonDisabled"
                            :loading="pageLoading"
                            class="white--text mt-4 ml-6"
                            x-small
                            tile
                            color="blue darken-4"
                            outlined
                            v-on:click="addButton"
                          >
                            <span style="font-size: 10px; font-weight: 700">Add Buttons</span>
                          </v-btn>
                        </v-col>
                        <template v-for="(item, index) of button_text_values">
                          <v-col :key="index + 1" md="3" class="my-auto py-0">
                            <!-- <label for="button_type" class="btx-label mt-">Button Type </label> -->
                          </v-col>
                          <v-col :key="index + 7825" md="9" class="py-0 d-flex">
                            <SelectInput
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              multiple
                              @change="buttonTypeChange(index)"
                              :items="filterSelectedButton(item.type)"
                              id="button_type"
                              item-text="text"
                              item-value="value"
                              v-model="item.type"
                              placeholder="Select button type"
                            ></SelectInput>
                            <v-icon class="ml-4" color="red" @click="deleteButton(index)"
                              >mdi-close</v-icon
                            >
                          </v-col>
                          <v-col md="3" :key="index + 10"></v-col>
                          <v-col :key="index + 1000" md="9" class="d-flex flex-column">
                            <v-flex v-if="item.type" md12 class="d-flex">
                              <v-col
                                md="2"
                                style="margin-top: -3px"
                                class="mt-0 font-level-3-bold"
                                :key="index + 47020"
                                >Button Text</v-col
                              >
                              <v-col
                                md="10"
                                :key="index + 45"
                                class="custom-textfield"
                                style="padding: 3px 4px 4px 4px !important"
                              >
                                <v-text-field
                                  dense
                                  outlined
                                  hideTopMargin
                                  :rules="[
                                    (v) =>
                                      (v ? v.length <= 25 : true) ||
                                      `Button text maximum 25 characters`,
                                    vrules.required(item.text, 'Button text'),
                                  ]"
                                  v-model="item.text"
                                  :class="{ required: !item.text }"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="template_name"
                                  placeholder="Enter button text"
                                ></v-text-field>
                              </v-col>
                            </v-flex>
                            <template v-if="item.type == 'URL'">
                              <v-flex md12 class="d-flex justify-end">
                                <v-btn
                                  :disabled="onlyOneParameter(item.url) ? true : false"
                                  x-small
                                  outlined
                                  color="blue darken-4"
                                  style="font-size: 10px; font-weight: 700"
                                  v-if="!endsWithDynamicValue(item.url)"
                                  tile
                                  @click="addButtonParameter(index)"
                                  class="blue--text mr-1 mb-1"
                                >
                                  <span style="font-size: 10px; font-weight: 700" class="text"
                                    >Add Button Parameters</span
                                  >
                                </v-btn>
                                <v-btn
                                  :disabled="!onlyOneParameter(item.url) ? true : false"
                                  outlined
                                  v-else
                                  @click="removeButtonParameter(index)"
                                  small
                                  color="blue darken-4"
                                  style="font-size: 10px; font-weight: 700"
                                  tile
                                  class="blue--text mr-1 mb-1"
                                  ><span>Remove Button Parameters</span></v-btn
                                >
                              </v-flex>

                              <v-flex md12 class="d-flex">
                                <v-col
                                  md="3"
                                  class="font-level-3-bold"
                                  style="margin-top: -3px !important"
                                  :key="index + 10"
                                  >Button URL</v-col
                                >
                                <v-col
                                  md="9"
                                  :key="index + 45"
                                  style="padding: 3px 4px 4px 4px !important"
                                >
                                  <v-text-field
                                    hide-details
                                    dense
                                    outlined
                                    :rules="[vrules.required(item.url, 'URL')]"
                                    :class="{ required: !item.url }"
                                    v-model="item.url"
                                    :disabled="pageLoading || endsWithDynamicValue(item.url)"
                                    :loading="pageLoading"
                                    id="template_name"
                                    placeholder="Enter Url with parameter"
                                  ></v-text-field>
                                </v-col>
                              </v-flex>
                              <v-flex v-if="endsWithDynamicValue(item.url)" md12 class="d-flex">
                                <template v-for="(item1, index) in item.parameters">
                                  <v-col md="3" class="mt-2 font-level-3-bold" :key="index + 10"
                                    >Button Parameter E.g.</v-col
                                  >
                                  <v-col md="9" :key="index + 45">
                                    <v-text-field
                                      hide-details
                                      dense
                                      outlined
                                      :rules="[
                                        vrules.required(
                                          item1.value_example,
                                          'Button Parameter Eg.'
                                        ),
                                      ]"
                                      :class="{ required: !item1.value_example }"
                                      v-model="item1.value_example"
                                      :disabled="
                                        pageLoading || endsWithDynamicValue(item1.value_example)
                                      "
                                      :loading="pageLoading"
                                      id="template_name"
                                      placeholder="Enter url parameter example"
                                    ></v-text-field>
                                  </v-col>
                                </template>
                              </v-flex>
                            </template>
                            <template v-if="item.type == 'PHONE_NUMBER'">
                              <v-flex md12 class="d-flex">
                                <v-col md="3" class="mt-2 font-level-3-bold mt-4" :key="index + 10"
                                  >Phone Number</v-col
                                >
                                <v-col md="9" :key="index + 45">
                                  <PhoneTemplate
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    :rules="[
                                      (v) =>
                                        (v ? v.length <= 20 : true) ||
                                        `Button text maximum 20 Number`,
                                      vrules.required(item.phone_number, 'Phone Number'),
                                    ]"
                                    :class="{ required: !item.phone_number }"
                                    id="phone-number"
                                    v-model="item.phone_number"
                                    placeholder="Phone Number"
                                  ></PhoneTemplate>
                                </v-col>
                              </v-flex>
                            </template>
                          </v-col>
                        </template>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col md="4" class="d-flex justify-center">
          <MobileFrame style="position:fixed;top:14rem ;z-index:40;!important;">
            <template v-slot:content>
              <div style="border-radius: 1rem; background-color: white">
                <div id="whatsapp-chat">
                  <!-- <div class="header-chat">
                    <div class="head-home">
                      <div class="info-avatar">
                        <img
                          src="https://files.elfsight.com/storage/9274ed8b-a2e8-4cf8-a4cf-fad383377f2b/7b75090c-19a2-452b-9d6b-c2a51ad4916f.jpeg"
                        />
                      </div>
                      <p>
                        <span class="whatsapp-name">Tedbree</span><br /><small
                          >Typically replies within an hour</small
                        >
                      </p>
                    </div>
                    <div class="get-new hide">
                      <div id="get-label"></div>
                      <div id="get-nama"></div>
                    </div>
                  </div> -->
                  <!-- <div class="home-chat"></div> -->
                  <div class="start-chat">
                    <div
                      pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
                      class="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
                    >
                      <div class="d-flex flex-column justify-space-between" style="height: 100%">
                        <div class="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
                          <div
                            style="opacity: 1; margin-left: 1px"
                            v-if="
                              template?.header_text ||
                              template?.file[0]?.file ||
                              template?.body_text ||
                              template?.footer_text
                            "
                            class="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                          >
                            <div class="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                              {{ matchingHeader() }}
                            </div>
                            <span style="display: none"> {{ upload_file_url }}</span>
                            <div
                              class="font-level-1-bold"
                              v-if="upload_file_url && template.header_type == 'image'"
                            >
                              <v-img
                                v-if="upload_file_url && template.header_type == 'image'"
                                style="height: 150px; width: 100%; border-radius: 0.2rem"
                                :src="upload_file_url"
                              ></v-img>
                            </div>
                            <span style="display: none"> {{ upload_file_url }}</span>

                            <div
                              class="font-level-1-bold grey lighten-4 p-2"
                              style="font-weight: 600; border-radius: 0.2rem"
                              v-if="upload_file_url && template.header_type == 'document'"
                            >
                              <v-icon color="red">mdi-file-pdf-box</v-icon>
                              <span style="font-weight: 500" class="font-level-2-bold ml-1">
                                {{ template.file[0].name }}
                              </span>
                              <!-- <v-img
                               
                                style="height: 150px; width: 100%; border-radius: 0.7rem"
                                :src="upload_file_url"
                              ></v-img> -->
                            </div>
                            <div class="mt-1">
                              {{ matchingBody() }}
                            </div>
                            <div class="mt-2">
                              {{ template.footer_text }}
                              <!-- {{button_text_values}} -->
                            </div>

                            <div class="WhatsappChat__Time-sc-1wqac52-5 cqCDVm">Now</div>
                          </div>
                          <template v-if="button_text_values[0]?.text">
                            <div
                              style="margin-top: 4px; border-radius: 0.7rem"
                              class="white px-4 py-1"
                              v-for="(item, index) in button_text_values"
                              :key="index + button_text_values.length"
                            >
                              <div
                                style="font-weight: 600; max-width: calc(100% - 40px)"
                                md="12"
                                class="font-level-2-bold cyan--text d-flex justify-center mx-1"
                              >
                                {{ item.text }}
                              </div>
                            </div>
                          </template>
                        </div>
                        <div style="position: absolute; bottom: 0; z-index: 9999">
                          <div class="d-flex justify-space-between align-center">
                            <div class="d-flex align-center mr-2 mb-2 custom-input">
                              <v-text-field
                                color="grey"
                                prepend-inner-icon="mdi-emoticon-excited-outline"
                                append-icon="mdi-camera"
                                hide-details
                                label="Type a Message"
                                solo
                                dense
                              ></v-text-field>
                              <!-- <v-icon color="grey lighten-1">mdi-emoticon-excited-outline</v-icon>
                                <div class="ml-3 grey--text">Type a Message</div>
                              </div>
                              <v-icon color="grey darken-1">mdi-camera</v-icon> -->
                            </div>
                            <div
                              class="d-flex justify-center align-center p-2 rounded-xl"
                              style="background: #095e54"
                            >
                              <v-icon size="20" color="white">mdi-microphone</v-icon>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </MobileFrame>
        </v-col>
      </div>
    </v-row>
  </v-sheet>
</template>
<script>
import TextInput from "@/view/components/TextInput";
import PhoneTemplate from "@/view/components/Phone";
import FileUpload from "@/view/components/FileUpload";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import {
  GET_TEMPLATE,
  CREATE_TEMPLATE,
  GET_HEADER_HANDLER,
  UPLOAD_TEMPLATE_FILE,
  GET_WHATSAPP_OPTION_API,
} from "@/core/lib/marketing.lib";
import MobileFrame from "@/view/module/marketing/MobileFrame.vue";
import { cloneDeep, filter, find } from "lodash";
export default {
  name: "template-create",
  title: "Create template",
  data() {
    return {
      pageLoading: false,
      bodyAccordion: 0,
      formValid: true,
      customerId: null,
      header_text_parameter: [],
      button_text_parameter: [],
      filterButtonList: [],
      whatsappOption: null,
      frontEnd: null,
      body_text_parameter: [],
      upload_file_url: null,
      template: {
        button_category_type: "",
        header_text: "",
        template_category: "",
        category: "",
        language: "",
        header_type: "none",
        body_text: "",
        template_type: "whatsapp",
        footer_text: "",
        file: [],
      },
      categoryList: [],
      templateCategory: [
        {
          text: "Utility",
          value: "UTILITY",
        },
        {
          text: "Marketing",
          value: "MARKETING",
        },
      ],
      languageList: [
        {
          text: "en",
          value: "en",
        },
        {
          text: "en-us",
          value: "en-us",
        },
      ],
      headerTypeList: [
        {
          text: "None",
          value: "none",
        },
        {
          text: "Text",
          value: "text",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Documents",
          value: "document",
        },
      ],
      broadcast: {
        name: null,
        type: [],
        segment: null,
        selectedTemplate: null,
        message: null,
        schedule_type: "now",
      },
      newButtons: null,
      segmentList: [],
      checkbox1: false,
      templateList: [],
      menu2: false,
      modal2: false,
      selectedTemplate: null,
      buttonTypeList: [
        {
          text: "Quick Reply",
          value: "QUICK_REPLY",
          disabled: false,
        },
        {
          text: "URL",
          value: "URL",
          disabled: false,
        },
        {
          text: "Phone Number",
          value: "PHONE_NUMBER",
          disabled: false,
        },
      ],
      segmentOption: {},
      fileURL: null,
      button_text_values: [],
      newTemplate: {
        header: null,
        body: null,
        footer: null,
      },
    };
  },
  methods: {
    fileRemove() {
      console.log("file remove");
      this.headerTypeChange();
    },
    inputFileImage() {
      this.imageKey = this.imageKey + 1;
      this.getFileUrl();
    },
    headerTypeChange() {
      this.template.file = [];
      this.header_text_parameter = [];
      this.template.header_text = "";
      this.upload_file_url = null;
    },
    async getFileUrl() {
      if (this.template.file[0].base64) {
        const _this = this;
        const template = cloneDeep(this.template);
        this.pageLoading = true;
        const body = {
          files: [
            {
              name: template.file[0].name,
              suffix: template.file[0].suffix,
              base64: template.file[0].base64,
            },
          ],
        };
        try {
          const data = await UPLOAD_TEMPLATE_FILE(body);
          console.log(data);
          this.upload_file_url = data;
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
    createHeaderParameters() {
      this.template.header_text = this.template.header_text + "{{1}}";
      this.header_text_parameter.push({
        text: "{{1}}",
        value: "",
      });
    },
    async getHeaderHandler() {
      if (this.template.file[0].base64) {
        const _this = this;
        const template = cloneDeep(this.template);
        this.pageLoading = true;
        const body = {
          file_size: template.file[0]?.size,
          file_type: template.file[0]?.type,
          file: template.file[0]?.base64,
        };
        try {
          const { header_handle } = await GET_HEADER_HANDLER(body);
          this.template.header_handle = header_handle;
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
    async getUploadFile() {
      const template = this.template;
      if (template?.file) {
        this.pageLoading = true;
        const body1 = {
          name: template.file[0].name,
          file_type: template.file[0].type,
          base64: template.file[0].base64,
        };
        try {
          this.fileURL = await UPLOAD_TEMPLATE_FILE(body1);
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },

    async updateOrCreate() {
      const _this = this;
      const template = cloneDeep(this.template);
      if (!template?.template_type) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Template Type is required" }]);
        return false;
      }
      const formErrors = _this.validateForm(_this.$refs.templateForm);
      if (formErrors.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
        return false;
      }
      if (!_this.$refs.templateForm.validate()) {
        return false;
      }
      if (template.header_type != "text") {
        await this.getHeaderHandler();
      }
      let bodyPara = [];
      this.body_text_parameter.forEach((ele) => {
        bodyPara.push(ele.value);
      });

      // const buttons = [];
      // this.button_text_values.forEach((ele) => {
      //   if (ele.button_type == "url") {
      //     buttons.push({
      //       type: "URL",
      //       text: ele.button_text,
      //       url: ele.button_url,
      //       example: [ele.button_url],
      //     });
      //   } else if (ele.button_type == "phone_number") {
      //     buttons.push({
      //       type: "PHONE_NUMBER",
      //       text: ele.button_text,
      //       phone_number: ele.phone_number,
      //     });
      //   } else if (ele.button_type == "QUICK_REPLY") {
      //     buttons.push({
      //       type: "QUICK_REPLY",
      //       text: ele.button_text,
      //     });
      //   }
      // });
      const textValueButton = cloneDeep(this.button_text_values);
      if (textValueButton.length) {
        this.button_text_values = textValueButton.map((ele) => {
          if (ele.type == "URL") {
            ele.example = [ele.parameters[0].value_example];
          }
          delete ele?.button_text;
          delete ele?.button_type;
          delete ele?.button_url;

          delete ele?.parameters;
          return ele;
        });
      }
      let body = {
        id: this.$route.name == "update-template" ? this.$route.params.id : null,
        name: template.name,
        template_category: template.category,
        category: template.template_category,
        language: template.language,
        header_type: template.header_type,
        file_url: (this.fileURL && this.fileURL) || "",
        file_size: (template.file[0]?.size && template.file[0]?.size) || 0,
        buttons_type: template.button_category_type == "none" ? "" : template.button_category_type,
        body: {
          text: (template?.body_text && template?.body_text) || null,
          text_values: bodyPara,
        },
        footer: {
          text: (template.footer_text && template?.footer_text) || null,
        },
        buttons: this.button_text_values,
      };
      if (template.header_type == "text") {
        const headerParameter = cloneDeep(this.header_text_parameter);
        let header = {
          text: (template?.header_text && template?.header_text) || null,
          text_values: (headerParameter.length && [headerParameter[0].value]) || "",
        };
        body = { ...body, header };
      } else {
        let header = {
          header_handle: [this.template.header_handle],
        };
        body = { ...body, header };
      }
      try {
        await CREATE_TEMPLATE(template?.template_type, body);
        if (this.$route.name == "create-template") {
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Template Created Successfully." },
          ]);
        } else {
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Template Updated Successfully." },
          ]);
        }
        this.$router.push({ name: "template" });
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },

    removeBodyParameter(index) {
      this.body_text_parameter.splice(index, 1);
      const count = index + 1;
      this.template.body_text = this.template.body_text.replace("{{" + count + "}}", "");
    },
    removeHeaderParameter(index) {
      this.header_text_parameter.splice(index, 1);
      const count = index + 1;
      this.template.header_text = this.template.header_text.replace("{{" + count + "}}", "");
    },
    checkDoubleCurly(event, string) {
      // const regex = /{{\s*\d+\s*}}/;
      if (
        string[string.length - 1] + event.key == "{{" ||
        string[string.length - 1] + event.key == "}}"
      ) {
        event.preventDefault();
      }
    },
    addButtonParameter(index) {
      const buttonTextValue = cloneDeep(this.button_text_values);
      buttonTextValue[index].url = buttonTextValue[index].url + "{{" + 1 + "}}";
      const list = buttonTextValue.map((ele) => {
        if (ele.type == "URL") {
          ele.parameters = [
            {
              text: "",
              value_example: "",
            },
          ];
        }
        return ele;
      });
      this.button_text_values = list;
    },
    removeButtonParameter(index) {
      const buttonTextValue = cloneDeep(this.button_text_values);
      const inputString = buttonTextValue[index].url;
      const regex = /{{([^}]+)}}/g;
      buttonTextValue[index].url = inputString.replace(regex, "");
      // buttonTextValue[index].parameters.splice(0, 1);
      const list = buttonTextValue.map((ele) => {
        if (ele.type == "URL") {
          ele.parameters = [];
        }
        return ele;
      });
      this.button_text_values = list;
    },
    addButton() {
      this.button_text_values.push({
        button_type: "",
        button_text: "",
        button_url: "",
        url: "",
        text: "",
        parameters: [
          {
            text: "",
            value_example: "",
          },
        ],
      });
    },
    deleteButton(index) {
      this.button_text_values.splice(index, 1);
    },
    buttonTypeChange(index) {
      this.button_text_values.splice(index, {
        button_type: "",
        button_text: "",
        button_url: "",
        text: "",
        url: "",
        parameters: [
          {
            text: "",
            value_example: "",
          },
        ],
      });
    },
    createBodyParameters() {
      const count = this.body_text_parameter.length + 1;
      this.template.body_text = this.template.body_text + "{{" + count + "}}";

      this.body_text_parameter.push({
        text: "{{" + count + 1 + "}}",
        value: "",
      });
    },

    async getWhatsappOption() {
      const _this = this;
      this.pageLoading = true;
      try {
        const data = await GET_WHATSAPP_OPTION_API();
        this.categoryList = data?.template_category;
        this.languageList = data?.languages;
        this.whatsappOption = data;
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    templateChange(template) {
      const template1 = this.segmentOption ? cloneDeep(this.segmentOption.templates) : [];
      template1.forEach((element) => {
        if (element.template_name == template) {
          this.selectedTemplate = element;
        }
      });
    },
    async getTemplate() {
      const _this = this;
      try {
        let data = await GET_TEMPLATE(this.$route.params?.id);
        this.template.category = data.template_category;
        this.template.template_category = data.category_name;
        this.template.language = data.language;
        this.template.name = data.template_name;
        this.template.header_type = data.header_type;
        this.template.header_text = data.frontend.header?.string;

        this.template.body_text = data.frontend.body?.string;
        this.template.footer_text = data.frontend?.footer;
        this.frontEnd = data.frontend.buttons;
        this.template.button_category_type = data?.buttons_type;
        this.buttonCategoryChange();
        if (data.frontend?.buttons && data.frontend?.buttons.length) {
          this.button_text_values = data.frontend.buttons.map((ele) => {
            if (ele.type == "PHONE_NUMBER") {
              ele.phone_number = data?.button_phone_number;
            }
            return ele;
          });
        }
        if (data.frontend.body?.parameters.length) {
          this.body_text_parameter = data.frontend.body?.parameters.map((ele) => {
            ele.value = ele.value_example;
            return ele;
          });
        }
        if (data.frontend.header?.parameters && data.frontend.header?.parameters.length) {
          this.header_text_parameter = data.frontend.header?.parameters.map((ele) => {
            ele.value = ele.value_example;
            return ele;
          });
        }

        // const list = data.frontend.buttons.map((ele, index) => {
        // this.template.button_text_values[index].button_type = ele.type;
        //   this.template.button_text_values.push({
        //     button_type: "url",
        //     button_text:ele?.text,
        //     button_url: ele?.url,
        //     button_url_example: parameters[index].value_example,
        //   });
        //   return ele;
        // });
        // console.log("list", list);
        // const matches = str.match(/{{(.*?)}}/g);
        // const values = matches.map((match) => match.slice(2, -2));
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      }
    },
    buttonCategoryChange() {
      let list = cloneDeep(this.buttonTypeList);
      if (this.template.button_category_type == "quick_reply") {
        list = list.filter((ele) => {
          if (ele.value == "QUICK_REPLY") {
            return ele;
          }
        });
      } else if (this.template.button_category_type == "action_type") {
        list = list.filter((ele) => {
          if (ele.value == "PHONE_NUMBER" || ele.value == "URL") {
            return ele;
          }
        });
      }
      this.filterButtonList = list;
      this.button_text_values = [];
    },
    filterSelectedButton(type) {
      const buttonList = cloneDeep(this.filterButtonList);
      const buttonTextValue = cloneDeep(this.button_text_values);
      const newList = filter(buttonList, (element) => {
        if (element.text != type) {
          if (!find(buttonTextValue, (row) => row.type == element.text)) {
            return element;
          }
        } else {
          return element;
        }
      });
      return newList;
    },
  },
  computed: {
    ...mapGetters(["errors"]),
    onlyOneParameter() {
      return (inputString) => {
        if (inputString) {
          const regex = /{{([^}]+)}}/g;
          const matches = inputString.match(regex);

          if (matches) {
            return matches.length;
          } else {
            return 0;
          }
        }
      };
    },
    dynamicButtonUrl() {
      return (index) => {
        let url = cloneDeep(this.selectedTemplate?.frontend?.buttons[index].url);
        const parameters = cloneDeep(this.selectedTemplate?.frontend?.buttons[index].parameters);
        if (url) {
          for (let index = 0; index < parameters.length; index++) {
            const element = parameters[index];
            if (element.value) {
              const count = index + 1;
              url = url.replaceAll("{{" + count + "}}", element.value);
            }
          }
        }
        return url;
      };
    },
    matchingBody() {
      return () => {
        if (this.template.body_text) {
          let inputString = this.template.body_text;
          let regex = /\{\{([^}]+)\}\}/g;
          let matches = inputString.match(regex);
          let extractedValues = [];
          if (matches) {
            for (const match of matches) {
              const extractedValue = match.substring(2, match.length - 2);
              extractedValues.push(extractedValue);
            }
          }

          for (let index = 0; index < this.body_text_parameter.length; index++) {
            const item = this.body_text_parameter[index];
            if (item.value) {
              const count = index + 1;
              inputString = inputString.replaceAll("{{" + count + "}}", item.value);
            }
          }
          return inputString;
        }
      };
    },
    matchingHeader() {
      return () => {
        if (this.header_text_parameter) {
          //   let string1 = cloneDeep(
          //  his.template.header_text
          // );
          // const headerParameter = cloneDeep(
          //   this.selectedTemplate?.frontend?.header?.parameters
          // );
          let inputString = this.template.header_text;
          let regex = /\{\{([^}]+)\}\}/g;
          let matches = inputString.match(regex);
          let extractedValues = [];
          if (matches) {
            for (const match of matches) {
              const extractedValue = match.substring(2, match.length - 2);
              extractedValues.push(extractedValue);
            }
          }

          for (let index = 0; index < this.header_text_parameter.length; index++) {
            const item = this.header_text_parameter[index];
            if (item.value) {
              const count = index + 1;
              inputString = inputString.replaceAll("{{" + count + "}}", item.value);
            }
          }
          return inputString;
        }
      };
    },
    isButtonDisabled() {
      if (this.template.button_category_type) {
        if (this.template.button_category_type == "quick_reply") {
          if (this.button_text_values.length > 2) {
            return true;
          } else {
            return false;
          }
        }
        if (this.template.button_category_type == "action_type") {
          if (this.button_text_values.length > 1) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
      return "  ";
    },
    endsWithDynamicValue() {
      return (inputString) => {
        const regex = /{{([^}]+)}}$/;
        return regex.test(inputString);
      };
    },
    title() {
      if (this.$route.name == "update-template") {
        return "Update Template";
      } else {
        return "Create Template";
      }
    },
  },
  components: {
    TextInput,
    TextAreaInput,
    SelectInput,
    FileUpload,
    PhoneTemplate,
    MobileFrame,
  },

  async mounted() {
    await this.getWhatsappOption();
    if (this.$route.name == "update-template") {
      await this.getTemplate();
    }
  },
};
</script>
